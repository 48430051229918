import React from "react"
import { Link } from "gatsby"

import styled from "styled-components"

const ResponsiveFooter = styled.div` 
width: 100%;
display: flex;
flex-direction: column;
min-height: 5rem;
background: var(--purple);
box-shadow: 0.5rem 0.5rem 0.5rem rgba(65,36, 86, 0.5);
border-bottom-left-radius: 1rem;
border-bottom-right-radius: 1rem;
align-items: center;
a {
    font-family: "poppins", sans-serif;
    font-style: normal;
    font-weight: 300;
    margin-bottom: 0.25;
    text-decoration: none;
    color: var(--gold);
    font-size: 1.4rem;
    cursor: pointer;
    :hover {
        color: var(--lpurple);
      } 
  }
  .Items a{
    margin-right: 2rem;
    padding-top: 2rem;
  }
@media (min-width: 768px) {
  flex-direction: row;
  .Items {
    width: 50%;
    padding-left: 3rem;
  }
  .CRight {
    width: 50%;
    padding-right: 3rem;
    display: flex;
    justify-content: flex-end;
  
}  
}
`; 

const Footer = () => {
  return (
    <>
    <ResponsiveFooter>
    <div className="Items">
      
        <Link to="/privacy">
        privacy
        </Link>
     
        <Link to="/terms">
         terms
        </Link>
      
        <Link to="/contact">
          contact
        </Link>
      </div>
      
      <div className="CRight">
      
      <Link to="/">
       &copy; Ask The Tarot {new Date().getFullYear()}. All Rights Reserved.
      </Link>
  
    </div>

  </ResponsiveFooter>
  </>
  )
}

export default Footer;
