import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"

const Background = ({ children }) => {
  const { backgroundImage } = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "BG_SS_DSK.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
            height: 1080
            quality: 50
            webpOptions: { quality: 80 }
            formats: [AUTO, WEBP]
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
    }
  `)

  const pluginImage = getImage(backgroundImage)

  return (
    <BgImage image={pluginImage} className="bg">
      {children}
    </BgImage>
  )
}
Background.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Background
