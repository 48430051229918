// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-authorise-js": () => import("./../../../src/pages/authorise.js" /* webpackChunkName: "component---src-pages-authorise-js" */),
  "component---src-pages-authorise-new-js": () => import("./../../../src/pages/authorise-new.js" /* webpackChunkName: "component---src-pages-authorise-new-js" */),
  "component---src-pages-authorise-reset-js": () => import("./../../../src/pages/authorise-reset.js" /* webpackChunkName: "component---src-pages-authorise-reset-js" */),
  "component---src-pages-chakra-js": () => import("./../../../src/pages/chakra.js" /* webpackChunkName: "component---src-pages-chakra-js" */),
  "component---src-pages-checkout-completed-js": () => import("./../../../src/pages/checkout/completed.js" /* webpackChunkName: "component---src-pages-checkout-completed-js" */),
  "component---src-pages-checkout-incomplete-js": () => import("./../../../src/pages/checkout/incomplete.js" /* webpackChunkName: "component---src-pages-checkout-incomplete-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dailyread-js": () => import("./../../../src/pages/dailyread.js" /* webpackChunkName: "component---src-pages-dailyread-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-purchase-finalise-js": () => import("./../../../src/pages/purchase-finalise.js" /* webpackChunkName: "component---src-pages-purchase-finalise-js" */),
  "component---src-pages-purchase-js": () => import("./../../../src/pages/purchase.js" /* webpackChunkName: "component---src-pages-purchase-js" */),
  "component---src-pages-readingresult-js": () => import("./../../../src/pages/readingresult.js" /* webpackChunkName: "component---src-pages-readingresult-js" */),
  "component---src-pages-readings-js": () => import("./../../../src/pages/readings.js" /* webpackChunkName: "component---src-pages-readings-js" */),
  "component---src-pages-tarot-js": () => import("./../../../src/pages/tarot.js" /* webpackChunkName: "component---src-pages-tarot-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

