import { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle` 
  :root {
    --purple: #412456;
    --gold: rgba(251, 201, 38, 0.5);
    --yellow: rgb(245, 223, 77);
    --lpurple: #dc68f5;
    --green: #31ab1f;
    --white: #fefefa;
    --black: #070707;
    --shade: rgba(65, 36, 86, 0.5);
    --shaded: rgba(65, 36, 86, 0.75);
  }

  html, body {
    margin: 0;
    padding: 0;
  }

  *, *::after, *::before {
    box-sizing: border-box;
  }

  html {
    background: var(--purple);
    font-size: 62.5%;
   }

  body {
    background: transparent;
    overflow: auto;
  }

  //Background Image

  .bg {
    width: 100vw;
    min-height: 100vh;
  }

  //Form

  .lobster {
    display: none;
  }

  //Navigation
 
 

  .navbar {
    width: 100%;
   
    background: var(--purple);
    box-shadow: 0.5rem 0.5rem 0.5rem rgba(220,104,245, 0.5);
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    .Container {
      background: var(--purple);
        border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
       box-shadow: 1rem 0.01rem 21rem 21rem rgba(220,104,245, 0.5);
    }
    a {
      margin-right: 2rem;
      letter-spacing: 0.15rem;
      color: var(--gold);
      font-size: 1.6rem;    
      :last-child {
        margin-right: 0.5rem;
      }
      :hover {
        color: var(--lpurple);
        text-decoration: none;
      } 
    }
   
    }
  

 .navbar-brand.logoWord {
    display: none;
    @media (min-width: 768px) {
      display: inline;
    }
  }
  

  .navbar-brand a {
    text-transform: uppercase;
    font-family: "Aviano-flare";
    font-weight: 500;
    font-style: normal;
  }

 button.navbar-toggler.collapsed {
   
    border-style: inset;
    border-color: var(--gold);
    border-width: thin;
    border-radius: 0.5rem;
    
    
  }

  
  



 
  

  


  //Bootstrap Elements

  //Buttons

  .btn {
    border-style: none;
    background: var(--purple);
    color: var(--gold);
    text-transform: uppercase;
    letter-spacing: 0.15rem;
    font-size: 1.5rem;
    font-weight: 600;
    padding: 0.5rem 0;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    :hover {
      color: var(--lpurple);
      background: var(--purple);
    }
  }


  .btn.ATC {
    border-style: none;
    background: var(--purple);
    color: var(--gold);
    text-transform: uppercase;
    letter-spacing: 0.15rem;
    font-size: 1.5rem;
    font-weight: 600;
    padding: 0.5rem 0;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    :hover {
      color: var(--white);
      background: var(--green);
      }
    }



  //Cards

  .card {
    border: 0.1rem solid var(--gold);
    border-radius: 1rem;
    background: var(--shade);
    box-shadow: 0.5rem 0.5rem 0.5rem rgba(65,36, 86, 0.5);
    .footer {
      border: none;
      background: var(--purple);
    }
    .gatsby-image-wrapper {
      border-top-right-radius: 1rem;
      border-top-left-radius: 1rem; 
      max-width: 32.2rem;
      margin: 0 auto;
    }
  }

  .card-header {
      color: var(--gold);
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;   
  }
  
  .card-title {
    color: var(--gold);
    font-size: 1.8rem;
  }  

  .card-subtitle {
    color: var(--gold);
    font-size: 1rem;
    padding-bottom: 1rem;
  }

  .card-body {
    color: var(--gold);
    font-size: 1.6rem;
  }
  
  .card-text {
      padding-bottom: 1rem;
      color: var(--gold);
      font-size: 1.2rem;
   
  }

  .FAQCARD {
    background: var(--shaded);
  }

  

  // Shopping Cart 

  .cartItemPrice {
    display: flex;
    align-items: center;
  }



  
  // Checkout Button

  .checkOut {
    border-style: none;
    background: var(--purple);
    color: var(--gold);
    text-transform: uppercase;
    letter-spacing: 0.25rem;
    font-size: 1.5rem;
    padding: 0.5rem;
    
    width: 90%;
    margin: 0 auto;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    :hover {
      color: var(--white);
      background: var(--green);
    }
  }
  
  //Home Page Carousel

  .carousel-container {
    width: 100%;
    margin: 0 auto;
  }

  //Tarot Card Meaning Images

  .TCardImg {
    max-width: 25rem;
    margin: 0 auto;
    .gatsby-image-wrapper {
      max-width: 25rem;
      margin: 0 auto;
      border-radius: 1rem;
      border: 0.075rem solid var(--gold);
      box-shadow: 0 0 0.5rem 0.5rem rgba(65, 36, 86, 0.5);
    }

  }

  //Sign In Forrms

  .AUTH {
    font-family: "poppins", sans-serif;
    font-weight: 600;
    a {
      color: var(--lpurple);
      font-size: 1.4rem;
      margin-top: 0;
    }
    p {
      color: var(--white);
      font-size: 1.4rem;
      margin-bottom: 0;
    }
  }
 
 #enquiry {
   display: flex;
   margin-top: 2rem;
  label {
    width: 90%;
  }
  input{
    width: 10%;
  }
}

.ChakraFront {
  width: 30rem;
  @media (min-width: 768px) {
    width: 60rem;
  }
}

.ChakraFront .card-body {
  background: var(--shade);
}

 

//Forms

form {
  background: var(--shade);
  border: 0.05rem solid var(--gold);
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0.5rem 0.5rem 0.5rem rgba(65,36, 86, 0.5);
  h3 {
    color: var(--gold);
    text-shadow: 0.2rem 0.2rem #412456;
    text-align: center;
  }
  h4 {
    color: var(--gold);
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  label {
  color: var(--gold);
  width: 100%;
  font-style: bold;
  font-size: 1.5rem;
  font-weight: 600;
   }
  input, textarea {
  background: var(--shade);
  border: 0.05rem solid var(--shaded);
  color: var(--gold);
  font-size: 1.5rem;
  margin-bottom: 1rem;
  width: 100%;
  
  :focus {
    background: var(--black);
    color: var(--white);
    font-size: 1.5rem; 
  }
 }
  button {
    border-style: none;
    background: var(--purple);
    color: var(--gold);
    text-transform: uppercase;
    letter-spacing: 0.15rem;
    font-size: 1.5rem;
    font-weight: 600;
    padding: 0.5rem 0;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    width: 100%;
    :hover {
      color: var(--lpurple);
      background: var(--purple);
    }
  }
}

.form-label {
  color: var(--gold);
  font-style: bold;
  font-size: 1.5rem;
  font-weight: 600;
}

.form-control {
  background: var(--shade);
  border: 0.05rem solid var(--shaded);
  color: var(--gold);
  font-size: 1.5rem;
  :focus {
    background: var(--black);
    color: var(--white);
    font-size: 1.5rem;
  }
 }

.terms-privacy {
  overflow: auto;
}

.thks {
  color: var(--gold);

}

.tandccheck {
  font-size: 1rem;
  text-align: center;
  a {
    color: var(--lpurple);
    text-decoration: underline;
    font-size: 1rem;
  }
}

.addressCard {
  width: 100%;
  margin: 0 auto;
  .card-text {
    margin: 0;
    padding-bottom: 0.25rem;
  }
  @media (min-width: 1025px) {
    width: 50%;
  }
}

.writeToMe {
  @media (min-width: 1025px) {
    width: 50%;
    margin: 0 auto;
  }
}

.address p {
  font-size: 1rem;
  color: var(--gold);
  text-align: center;
  
}


.modal-content {
  width: 100%;
  min-height: 100%;
  display: flex;
  background: #886899;

}
.close-modal {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-right: 1rem;
}

.close-modal button {
  border-radius: 50%;
  background: var(--purple);
  color: var(--gold);
  border: 0.05rem solid var(--white);
  font-size: 1.4rem;
  
}

.modal-price {
   display: flex;
  flex-direction: row;
  align-items: baseline;
  width: 100%;
  margin: 0 auto;
  
  justify-items: center;
  justify-content: center;
}

.modal-price h3 {
  text-shadow: 0.25rem 0.25rem #412456;
  color: var(--gold);
}

.modal-price h6 {
  color: var(--purple);
  margin-left: 1rem;
}

.modal-view {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  @media (min-width: 768px) {
    width: 50%;
  }
  @media (min-width: 1024px) {
    width: 25%;
  }
}


.modal-view .gatsby-image-wrapper {
  border: 0.15rem solid var(--gold);
}

.modal-desc {
  font-size: 1.4rem;
  color: var(--gold);
  padding: 1rem;
}

.modal-detail {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
}

.modal-detail div:last-child {
  flex: 1;
}

.modal-spread {
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  color: var(--purple);
}

.modal-atc {
  width: 100%;
  margin: 1rem auto;  
}

.chakra-modal-view, .login-modal-view {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.chakra-modal-header h2 {
  text-align: center;
  padding-bottom: 1.5rem;
  color: var(--gold);
 text-shadow: 0.25rem 0.25rem #412456;
}

.chakra-modal-header p {
  color: var(--purple);
}

.chakra-modal-desc {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: subgrid;
  margin: 1rem auto;
  gap: 1rem;
  @media (min-width: 900px) {
  display: grid;
  grid-template-columns: 30% 30% 30%;
  grid-template-rows: subgrid;
  gap: 2rem;
  margin: 1rem auto;
  justify-content: center;
}
}

.chakra-modal-signs {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: subgrid;
  gap: 1rem;
  margin: 1rem auto;
  
  @media (min-width: 768px) {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: subgrid;
  gap: 2rem;
  margin: 1rem auto;
}
}

.chakra-modal-signs, .chakra-modal-desc div h5 {
  color: var(--gold);
  
}

.chakra-modal-desc, .chakra-modal-signs div p {
  color: var(--purple);
}

.close-modal-new-chakra {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
  
}

.close-modal-new-chakra  button {
  border-style: none;
    background: var(--purple);
    color: var(--gold);
    text-transform: uppercase;
    letter-spacing: 0.15rem;
    font-size: 1.5rem;
    font-weight: 600;
    padding: 0.5rem 0;
    border-radius: 1rem;
    width: 75%;
    :hover {
      color: var(--lpurple);
      background: var(--green);
    }
  
}

.login-modal-data {
  width: 80%;
  flex: 1;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-modal-data p {
  color: var(--purple);
  margin: 1.5rem auto;
  font-size: 1.4rem;
}

.login-modal-button {
  border-style: none;
  background: transparent;
  color: var(--gold);
  font-size: 1.4rem;

}

.login-close-modal {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
  
}

.login-close-modal  button {
  border-style: none;
    background: var(--purple);
    color: var(--gold);
    text-transform: uppercase;
    letter-spacing: 0.15rem;
    font-size: 1.5rem;
    font-weight: 600;
    padding: 0.5rem 0;
    border-radius: 1rem;
    width: 75%;
    :hover {
      color: var(--lpurple);
      background: var(--green);
    }
  
}



`

export default GlobalStyles
