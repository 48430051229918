import React from "react"
import "firebase/auth"
import "firebase/firestore"
import Layout from "./src/components/layout"
import { CartProvider } from "./src/contexts/cartcontext"
import { AuthProvider } from "./src/contexts/authcontext"
import { DailyProvider } from "./src/contexts/dailycontext"

export function wrapPageElement({ element, props }) {
  return (
    <DailyProvider>
      <Layout {...props}>{element}</Layout>
    </DailyProvider>
  )
}

export const wrapRootElement = ({ element }) => {
  return (
    <AuthProvider>
      <CartProvider>{element}</CartProvider>
    </AuthProvider>
  )
}
