import { createGlobalStyle } from "styled-components"

const Typography = createGlobalStyle`

h1, h2, h3, h4, h5, h6, p, a {
  margin: 0;
  padding: 0;
  text-rendering: optimizeLegibility;
}

h1 {
  font-family: "Aviano-flare", sans-serif;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 1rem;
  letter-spacing: 0.35rem;
}

h2 {
  font-family: "Aviano-flare", sans-serif;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 0.8rem;
  letter-spacing: 0.3rem;
}

h3 {
  font-family: "Aviano-flare", sans-serif;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 0.7rem;
  letter-spacing: 0.25rem;
}

h4 {
  font-family: "Aviano-flare", sans-serif;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 0.5rem;
  letter-spacing: 0.2rem;
}

h5, h6 {
  font-family: "Aviano-flare", sans-serif;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 0.25rem;
  letter-spacing: 0.15rem;
}

p {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 0.25rem;
  font-size: 1.4rem;
}

a {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  margin-bottom: 0.25rem;
  color: var(--gold);
  text-decoration: none;
  cursor: pointer;
  :hover {
    text-decoration: none;
    color: var(--lpurple)
  }
}

`;

export default Typography;