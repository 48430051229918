import React, { useState } from "react"
import { Link } from "gatsby"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import Container from "react-bootstrap/Container"
import Logo from "../images/logo"
import { useAuth } from "../contexts/authcontext"

export default function Header() {
  const [error, setError] = useState("")
  const { currentUser, logout } = useAuth()

  async function handleLogout() {
    setError("")
    try {
      await logout()
    } catch {
      setError("Log Out Failed", error)
    }
  }

  return (
    <header>
      <Navbar collapseOnSelect expand="md" variant="dark">
        <Container>
          <Navbar.Brand>
            <Link to="/">
              <Logo />
            </Link>
          </Navbar.Brand>
          <Navbar.Brand className="logoWord">
            <Link to="/">Ask The Tarot</Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto">
              <Link to="/readings">readings</Link>
              <Link to="/about">about</Link>
              <Link to="/faq">faq</Link>
              {!currentUser ? null : (
                <Link to="/" onClick={handleLogout}>
                  logout
                </Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  )
}
