import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import "normalize.css"
import "bootstrap/dist/css/bootstrap.min.css"
import styled from "styled-components"
import Container from "react-bootstrap/Container"
import Header from "./header"
import GlobalStyles from "../styles/globalstyles"
import Typography from "../styles/typography"
import Footer from "../components/footer"
import Background from "../components/background"

const StyledContainer = styled.div`
  width: 90vw;
  min-height: 100vh;
  margin: 4rem auto 2rem;
  display: flex;
  flex-direction: column;
  background: var(--shade);
`

const StyledContentContainer = styled.div`
  width: 90%;
  /* min-height: 90vh; */
  margin: 2rem auto;
  background: transparent;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  /* box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.75); */
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <GlobalStyles />
      <Typography />
      <Background>
        <Container
          fluid
          className="w-100 d-flex justify-content-center align-items-center"
        >
          <StyledContainer>
            <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
            <StyledContentContainer>{children}</StyledContentContainer>
            <Footer />
          </StyledContainer>
        </Container>
      </Background>
    </>
  )
}
Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
