import React, { useContext, useState, useEffect } from "react"
import firebase from "gatsby-plugin-firebase"
import { generateUserDocument } from "../../firebase"

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)
 

  function signup(email, password) {
    return firebase.auth().createUserWithEmailAndPassword(email, password)
  }  
 
  function userdetails(user, { displayName }) {
    return generateUserDocument(user, { displayName })
  }

  async function login(email, password) {
    await firebase.auth().signInWithEmailAndPassword(email, password);
  }

  function logout() {
    return firebase.auth().signOut()
  }

  function resetPassword(email, actionCodeSettings) {
    return firebase.auth().sendPasswordResetEmail(email, actionCodeSettings)
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email)
  }

  function updatePassword(password) {
   return currentUser.updatePassword(password)
  }
  
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async userAuth => {
      const user = await generateUserDocument(userAuth)
      setCurrentUser(user)
    setLoading(false)
   })
    return unsubscribe
  }, [])



  const value = {
    currentUser,
    login,
    signup,
    userdetails,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
