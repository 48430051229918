import React, { useState } from "react"

const DailyContext = React.createContext()

export function DailyProvider({ children }) {
  const [love, setLove] = useState([])
  const [you, setYou] = useState([])
  const [career, setCareer] = useState([])

  const value = {
    love,
    setLove,
    you,
    setYou,
    career,
    setCareer,
  }

  return <DailyContext.Provider value={value}>{children}</DailyContext.Provider>
}

export default DailyContext
