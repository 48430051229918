import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components"

const LogoStyled = styled.div`
  position: relative;
  z-index: 5;
  top: 0;
  left: 0;
  margin-top: 2rem;
  width: 7.5rem;
  height: 7.5rem;
  background-color: transparent;
  padding: 0;
  @media (min-width: 768px) {
    width: 9rem;
    height: 9rem;
  }
`;

const Logo = () => {
  const data = useStaticQuery(graphql`{
  placeholderImage: file(relativePath: {eq: "att.png"}) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
}
`)

  if (!data?.placeholderImage?.childImageSharp?.gatsbyImageData) {
    return <div>Picture not found</div>
  }

  return (
    <LogoStyled>
   <GatsbyImage image={data.placeholderImage.childImageSharp.gatsbyImageData} alt="ask the tarot logo" />
   </LogoStyled>
  );
}

export default Logo;
